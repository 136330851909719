import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  HotelsDashboardPage,
  HOTELS_MULTI_DASHBOARD_COMPONENT_ID,
  HOTEL_RUNNER_APP_ID,
  mapHotelsPageToPageIndexInSidebar,
} from './hotels.constants';

export async function openHotelsPagesPanel({
  editorSDK,
}: {
  editorSDK: FlowEditorSDK;
}) {
  const { check: checkIfDeepLinkAvailable, show: triggerDeepLink } =
    editorSDK.editor.deeplink;

  const deepLinkToHotelsPagesAvailable = await checkIfDeepLinkAvailable(
    'token',
    {
      type: 'pagesPanel',
      params: [HOTEL_RUNNER_APP_ID],
    }
  );

  if (deepLinkToHotelsPagesAvailable) {
    triggerDeepLink('token', {
      type: 'pagesPanel',
      params: [HOTEL_RUNNER_APP_ID],
    });
  }
}

export function openHotelsDashboardPage({
  editorSDK,
  pageName,
  instance,
  referralInfo,
}: {
  editorSDK: FlowEditorSDK;
  pageName: HotelsDashboardPage;
  instance: string;
  referralInfo?: string;
}) {
  editorSDK.editor.openDashboardPanel('token', {
    url: getPathToHotelsDashboardPage({
      pageName,
      instance,
      referralInfo,
    }),
    closeOtherPanels: false,
  });
}

function getPathToHotelsDashboardPage({
  pageName,
  instance,
  referralInfo,
}: {
  pageName: HotelsDashboardPage;
  instance: string;
  referralInfo?: string;
}) {
  const multiDashboardPart = encodeURIComponent(
    `${HOTELS_MULTI_DASHBOARD_COMPONENT_ID}|${mapHotelsPageToPageIndexInSidebar[pageName]}`
  );

  return `app/${HOTEL_RUNNER_APP_ID}/${multiDashboardPart}?instance=${instance}${
    referralInfo ? `&referralInfo=${referralInfo}` : ''
  }`;
}
