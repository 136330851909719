import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { install } from '../components/webComponent/editor.controller';
import { IWebComponent } from '../components/webComponent/elements/customElement';
import {
  addComponentToPage,
  filterPage,
  filterWidgets,
  findPagesThatHaveNotBeenInstalledInThePast,
  openWidgetSelectorModal,
} from './helpers';

export interface AppData {
  applicationId: number;
  appDefinitionId: string;
  instance: string;
  instanceId: string;
  isWixTPA: boolean;
  components?: [];
  version?: string;
}

export const firstInstallFlow = async ({
  editorSDK,
  webComponents,
  metaSiteData,
  flowApi,
  appData,
  appDefinitionId,
  TOKEN,
}: {
  editorSDK: FlowEditorSDK;
  webComponents: IWebComponent[];
  metaSiteData: any;
  flowApi: FlowAPI;
  appData: AppData;
  appDefinitionId: string;
  TOKEN: string;
}) => {
  const webComponentsPages = filterPage(webComponents as IWebComponent[]);
  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages as IWebComponent[],
    editorSDK
  );

  if (pagesToAdd.length !== 0) {
    await install(editorSDK, appDefinitionId, metaSiteData, flowApi);
  } else {
    const widgets = filterWidgets(webComponents as IWebComponent[]);
    const pageRef = await editorSDK.pages.getCurrent(TOKEN);

    // checking if the url params have data about the widget to add
    const widgetWithMetaSiteData = widgets.find((widget) => {
      return widget?.componentId === metaSiteData?.componentId;
    });

    const widgetToAdd =
      widgets.length === 1 ? widgets[0] : widgetWithMetaSiteData;

    if (widgetToAdd) {
      await addComponentToPage({
        flowApi,
        componentDefinition: widgetToAdd,
        editorSDK,
        instanceId: appData.instanceId,
        applicationId: appData.appDefinitionId,
        metaSiteData,
        pageRef,
      });
    } else {
      openWidgetSelectorModal({ editorSDK });
    }
  }
};
