import { ComponentType } from '@wix/ambassador-app-service-webapp/types';

export const hasExternalDashboardUrl = (
  appData: Partial<{
    components: { type: ComponentType; data: { embedded?: boolean } }[];
  }>
): boolean => {
  const firstDashboardComp: any = appData.components?.find(
    (comp: any) => comp.type === ComponentType.DASHBOARD
  );

  return !!(firstDashboardComp && !firstDashboardComp?.data?.embedded);
};
