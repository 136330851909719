import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  addComponentToPage,
  createNewPage,
  filterPage,
  findPagesThatHaveNotBeenInstalledInThePast,
} from '../../utils/helpers';
import component from './.component.json';
import { IWebComponent } from './elements/customElement';

export const TOKEN = 'token';

// install will happen only first time and only for pages.
export async function install(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  metaSiteData: any = {},
  flowApi: FlowAPI
) {
  const applicationId = await editorSDK.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );
  // @ts-expect-error
  const webComponents: IWebComponent[] = appData.components.filter(
    (comp: { type: string }) => comp.type === 'WEB'
  );
  const webComponentsPages = filterPage(webComponents as IWebComponent[]);

  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages as IWebComponent[],
    editorSDK
  );

  for (const componentDefinition of pagesToAdd) {
    const pageRef = await createNewPage({
      componentDefinition,
      editorSDK,
      appDefinitionId,
    });

    await addComponentToPage({
      flowApi,
      pageRef,
      componentDefinition,
      editorSDK,
      instanceId: appData.instanceId,
      applicationId,
      metaSiteData,
      fullWidth: true,
      isPage: true,
    });
  }

  pagesToAdd.length > 1 &&
    (await editorSDK.pages.navigateTo(TOKEN, {
      pageRef: { id: pagesToAdd[0].componentId as string },
    }));
}

function createCustomElement(): { type: string } {
  return {
    type: component.id,
  };
}

export default createCustomElement();
