import {
  Component,
  ComponentType,
} from '@wix/ambassador-app-service-webapp/types';
import { HttpClient } from '@wix/http-client';
import { ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APESTER_APP_ID } from '../app-specific-logic/app-ids';
import {
  generateURL,
  IWebComponent,
} from '../components/webComponent/elements/customElement';
import { dashboardAction, getCustomElementTagName } from './helpers';
import { httpClient, initHttpClient } from './httpClient';
import { hasExternalDashboardUrl } from './utils';
import componentJson from '../components/webComponent/.component.json';

initHttpClient(new HttpClient({ baseURL: '' }));

const TOKEN = 'token';
const GENERAL_APPLICATION_ID = '22bef345-3c5b-4c18-b782-74d4085112ff';

const getEditorSdkSource = (editorSDK: FlowEditorSDK) =>
  editorSDK.info.getSdkVersion(TOKEN).scriptSrc;

export const panelUrlBuilder = ({
  editorSDK,
  componentRef,
  isApester,
  compId,
  version,
  metaSiteId,
  tagName,
}: {
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
  isApester: boolean;
  compId?: string;
  version: string;
  metaSiteId: string;
  tagName: string;
}) => {
  const apesterBaseURl = `https://editor.wixapps.net/render/prod/settings/web-component-wrapper/${process.env.ARTIFACT_VERSION}/${componentJson.id}`;

  const baseUrl = isApester
    ? apesterBaseURl
    : `https://www.wix.com/_serverless/dynamic-settings-renderer`;

  return `${baseUrl}?wix-sdk-version=${getEditorSdkSource(
    editorSDK
  )}&componentId=${
    componentRef.id
  }&compId=${compId}&version=${version}&metaSiteId=${metaSiteId}&tagName=${tagName}`;
};

export async function openDashboard(editorSDK: FlowEditorSDK) {
  const appId = await editorSDK.document.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(appId, appId);

  // first find multiDashboard comp type and send back the comp id. we dont care about multi dash... just give back comp id for first dashboard
  const firstDashboardComp: any = appData.components?.find(
    (comp: any) => comp.type === ComponentType.DASHBOARD
  );

  const externalLink: string = firstDashboardComp?.data?.url || '';

  if (hasExternalDashboardUrl(appData)) {
    editorSDK.editor.openModalPanel(TOKEN, {
      height: 768,
      width: 1366,
      url: externalLink,
    });
  } else {
    // need to delete this type is wrong but this is for refactor
    const dashboard = appData.components?.find(
      (comp: Component) => comp.compType === ComponentType.MULTIPLE_DASHBOARDS
    );

    dashboardAction({
      //@ts-expect-error
      multiDashboardData: dashboard && dashboard?.compId,
      editorSDK,
      appDefId: appData.appDefinitionId,
      instance: appData.instance,
    });
  }
}

export async function openSettingsPanel(
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef
) {
  const appId = await editorSDK.document.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(appId, appId);

  const controllerData = await editorSDK.document.controllers.getData(TOKEN, {
    controllerRef: componentRef,
  });

  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string; componentId: string }) =>
          comp.type === 'WEB' &&
          comp.componentId === controllerData?.config?.componentId
      )) ||
    {};

  // get application data to set the title, height, width
  editorSDK.editor.openComponentPanel(TOKEN, {
    title: webComponent?.data?.modalTitle ?? 'Settings',
    url: panelUrlBuilder({
      editorSDK,
      componentRef,
      isApester: appData?.appDefinitionId === APESTER_APP_ID,
      compId: webComponent.componentId,
      version: appData.version as string,
      metaSiteId: await editorSDK.info.getMetaSiteId(TOKEN),
      tagName: (await getCustomElementTagName(editorSDK, componentRef, [
        webComponent,
      ])) as string,
    }),
    height: 582,
    width: 402,
    componentRef,
    helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
  });
}

export async function addComponent({
  editorSDK,
  fullWidth = false,
  componentDefinition,
  pageRef,
}: {
  editorSDK: FlowEditorSDK;
  componentDefinition: any;
  fullWidth?: boolean;
  pageRef: PageRef;
}) {
  const componentRef = await editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
  return componentRef;
}

export async function findComponentsByType(
  editorSDK: FlowEditorSDK,
  parentRef: ComponentRef,
  componentType: string
) {
  const children = await editorSDK.document.components.getChildren(TOKEN, {
    componentRef: parentRef,
    recursive: true,
  });

  const result: ComponentRef[] = [];

  await Promise.all(
    children.map(async (child) => {
      const type = await editorSDK.components.getType(TOKEN, {
        componentRef: child,
      });

      if (type === componentType) {
        result.push(child);
      }
    })
  );

  return result;
}

export async function isEligibleForUpgrade(
  editorSDK: FlowEditorSDK | null,
  applicationId: string
): Promise<boolean> {
  if (!editorSDK) {
    return false;
  }
  try {
    const generalApp = await editorSDK.document.tpa.app.getDataByAppDefId(
      GENERAL_APPLICATION_ID,
      GENERAL_APPLICATION_ID
    );
    const generalInstance = generalApp.instance;

    const managedAppsData: any = await httpClient.get(
      'https://editor.wix.com/_api/marketplace-api/v1/managed-apps',
      { headers: { Authorization: generalInstance }, withCredentials: true }
    ); // TODO: support editor x and ADI

    const managedWebSolutions =
      managedAppsData?.data?.managedWebSolutions ?? [];

    return managedWebSolutions.some(
      (item: {
        eligibleForUpgrade: boolean;
        webSolutionBase: { id: string };
      }) =>
        item?.webSolutionBase?.id === applicationId && item.eligibleForUpgrade
    );
  } catch (error) {
    console.error('Error fetch managed apps');
    return false;
  }
}

export async function getMetaSiteCacheData(
  editorSDK: FlowEditorSDK
): Promise<any> {
  try {
    const metaSiteId: string = await editorSDK.document.info.getMetaSiteId(
      TOKEN
    );
    const cacheData: any =
      (
        await httpClient.get(
          `https://editor.wix.com/_serverless/deep-link-editor-cache/data/${metaSiteId}`
        )
      )?.data ?? {};
    return cacheData?.data?.data;
  } catch (e) {
    return undefined;
  }
}

export async function deleteMetaSiteCacheData(
  editorSDK: FlowEditorSDK
): Promise<void> {
  try {
    const metaSiteId: string = await editorSDK.document.info.getMetaSiteId(
      TOKEN
    );

    const deleteResult: any = await httpClient.delete(
      `https://editor.wix.com/_serverless/deep-link-editor-cache/data/${metaSiteId}`
    );

    if (deleteResult?.status === 200 && deleteResult?.data?.status === 204) {
      return;
    }

    return;
  } catch (e) {
    return;
  }
}

const updateComponentData = async ({
  editorSDK,
  instanceId,
  webComponent,
  component,
}: {
  editorSDK: FlowEditorSDK;
  instanceId: string;
  webComponent: IWebComponent;
  component: ComponentRef;
}) => {
  const scriptTag = webComponent.data?.scriptTag;
  const tagName = webComponent.data?.tagName;

  const currentComponentData = (await editorSDK.document.components.data.get(
    TOKEN,
    {
      componentRef: component,
    }
  )) as { initialAttributes: string; url: string; tagName: string };

  const url = generateURL(scriptTag, instanceId);

  const isSameAppInstanceId =
    currentComponentData?.initialAttributes.includes(instanceId);

  const shouldUpdateUrl =
    !!currentComponentData?.url && currentComponentData?.url !== url;

  const shouldUpdateTagName =
    !!currentComponentData?.tagName &&
    currentComponentData?.tagName !== tagName;

  if (isSameAppInstanceId) {
    const fieldsToUpdate = {
      ...(shouldUpdateTagName ? { tagName } : {}),
      ...(shouldUpdateUrl ? { url } : {}),
    };
    Object.keys(fieldsToUpdate).length > 0 &&
      (await editorSDK.document.components.data.update(TOKEN, {
        componentRef: component,
        data: fieldsToUpdate,
      }));
  }
};

const getAppWidgetComponent = async ({
  editorSDK,
  component,
}: {
  editorSDK: FlowEditorSDK;
  component: ComponentRef;
}) => {
  const ancestors = await editorSDK.components.getAncestors(TOKEN, {
    componentRef: component,
  });

  const appWidgetComponent = ancestors.find(async (ancestor) => {
    const controllerConnections =
      await editorSDK.controllers.getControllerConnections(TOKEN, {
        controllerRef: ancestor,
      });

    return controllerConnections.some(
      (controllerConnection) =>
        controllerConnection.connection.role === 'webComponent' &&
        controllerConnection.componentRef.id === component.id
    );
  });

  return appWidgetComponent;
};

export const updateComponentsData = ({
  editorSDK,
  instanceId,
  webComponents,
  components,
}: {
  editorSDK: FlowEditorSDK;
  instanceId: string;
  webComponents: IWebComponent[];
  components: ComponentRef[];
}) => {
  components.map(async (component) => {
    const appWidgetComponent = await getAppWidgetComponent({
      editorSDK,
      component,
    });

    if (appWidgetComponent) {
      const data = await editorSDK.document.controllers.getData(TOKEN, {
        controllerRef: appWidgetComponent,
      });

      const webComponent = webComponents.find(
        (comp) => comp.componentId === data.type
      );

      if (webComponent) {
        updateComponentData({ editorSDK, instanceId, webComponent, component });
      }
    }
  });
};
