import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export async function updateInstanceId(
  editorSDK: FlowEditorSDK,
  appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  },
  originInstanceId: string
): Promise<void> {
  const customElementComponentRefs =
    await editorSDK.document.components.findAllByType('token', {
      componentType: 'wixui.CustomElementComponent',
    });

  for (const customElementComponentRef of customElementComponentRefs) {
    const componentData = await editorSDK.components.data.get('token', {
      componentRef: customElementComponentRef,
    });

    // @ts-ignore
    const initialAttributes = JSON.parse(componentData.initialAttributes);

    const wixConfig = JSON.parse(initialAttributes.wixconfig);

    const newWixConfig = {
      ...wixConfig,
      instanceId: appData.instanceId,
      originInstanceId,
    };

    const newInitialAttributes = {
      ...initialAttributes,
      wixconfig: JSON.stringify(newWixConfig),
    };

    // @ts-ignore
    componentData.initialAttributes = JSON.stringify(newInitialAttributes);

    // @ts-ignore
    const url = new URL(componentData.url);
    url.searchParams.delete('instanceId');
    url.searchParams.append('instanceId', appData.instanceId);

    // @ts-ignore
    componentData.url = url.toString();

    editorSDK.components.data.update('token', {
      componentRef: customElementComponentRef,
      data: componentData,
    });
  }
}
