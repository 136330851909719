export enum HotelsDashboardPage {
  RoomTypes = 'RoomTypes',
  RoomRates = 'RoomRates',
  Extras = 'Extras',
}
export const mapHotelsPageToPageIndexInSidebar: Record<
  HotelsDashboardPage,
  number
> = {
  [HotelsDashboardPage.RoomTypes]: 2,
  [HotelsDashboardPage.RoomRates]: 3,
  [HotelsDashboardPage.Extras]: 4,
};

export const HOTELS_MULTI_DASHBOARD_COMPONENT_ID =
  '1c5b7dec-159e-48cf-b618-6d77d7a17f8c';

export const HOTEL_RUNNER_APP_ID = '826670ba-3a6b-4157-ac72-7c4fca9ce220';

const HOTELS_BOOKING_PAGE_WIDGET_ID = '5aec6ec6-3bc0-4193-9ed8-2cfd7cf6227f';

const HOTELS_STAGING_BOOKING_PAGE_WIDGET_ID =
  '3aa4cf22-ee63-4d08-994d-c2cdfa287d08';

export const HOTELS_BOOKING_PAGES = [
  HOTELS_BOOKING_PAGE_WIDGET_ID,
  HOTELS_STAGING_BOOKING_PAGE_WIDGET_ID,
];
